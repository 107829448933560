<script lang="ts" setup>
import type { Game, GameLastWinner } from "@/types";

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	winnerData?: GameLastWinner | undefined;
	hideFavorites?: boolean;
	skeleton?: boolean;
}>();

const emit = defineEmits<{ (e: "toggleFavorite"): void; (event: "play"): void }>();

const { open } = useAppModals();
const { data: appInit } = useAppInitData();
const prepareImgUrl = useImage();
const loginGuard = useLoginGuard();

const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 255
})})`;

const { t } = useT();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const handleToggle = () => {
	emit("toggleFavorite");
};

const handleOverlayClick = () => {
	loginGuard({
		success() {
			emit("play");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>
<template>
	<div :class="{ 'card-wrapper': true, 'has-skeleton': skeleton }">
		<AAnimationSkeleton v-if="skeleton" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>
		<ACard
			v-else
			:img-attrs="{
				width: 310,
				height: 336,
				src: `${baseImageUrl}${props.game?.logo || props.game?.img}`,
				quality: 70,
				format: 'webp',
				loading: 'lazy'
			}"
			height="auto"
			stretch="112.4%"
			variant="game"
			width="auto"
			:data-tid="`gcard-${game.id}`"
			@click="handleOverlayClick"
		>
			<MGameActivityFeedBadge :winner="winnerData" />
			<div v-if="(props.game.isNew || props.game.isFishing) && !props.game.isOnlyForApp" class="badge-wrap">
				<ABadge v-if="props.game.isFishing" background="var(--gdynia)" variant="info" autosize>
					<AText :modifiers="['uppercase', 'semibold']" variant="topeka"> {{ t("game fish") }}</AText>
				</ABadge>
				<ABadge v-if="props.game.isNew" background="var(--cebal)" variant="info" autosize>
					<AText :modifiers="['uppercase', 'semibold']" variant="topeka"> {{ t("game new") }}</AText>
				</ABadge>
			</div>
			<div v-if="!appInit?.isGuest && !hideFavorites" class="top-block">
				<AButton
					:modifiers="props.game.isFavorite ? ['active'] : undefined"
					variant="toolbar"
					:data-tid="`fav-${game.id}`"
					@click.stop="handleToggle"
				>
					<ASvg
						:class="[props.game.isFavorite ? 'text-caracas' : 'text-cannes']"
						name="games/favorite"
						class="fav-icon"
					/>
				</AButton>
			</div>
			<div v-if="props.game.isOnlyForApp" class="lock-overlay">
				<AOverlay :modifiers="['blur', 'flex-center']" bgColor="var(--chandigarh)">
					<ASvg filled name="36/locked" />
				</AOverlay>
			</div>
			<AAnimationFadeIn v-else>
				<AOverlay bgColor="var(--changshu)">
					<div class="game-content">
						<div class="center-block">
							<AButton :modifiers="['circle']" variant="primary" size="xl" class="play" :data-tid="`gplay-${game.id}`">
								<ASvg name="games/play" />
							</AButton>
						</div>
					</div>
				</AOverlay>
			</AAnimationFadeIn>
		</ACard>
	</div>
</template>

<style lang="scss" scoped>
.card-wrapper {
	--a-card-img-width: 210px;
	--a-card-img-height: 236px;
}

.has-skeleton {
	position: relative;
	--a-skeleton-background-color: var(--cipolletti);
}
.skeleton-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.lock-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 64px;
	height: 64px;
	position: absolute;
	border-radius: 10px;
	overflow: hidden;
	font-size: 40px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

	@include media-breakpoint-down(md) {
		width: 40px;
		height: 40px;
		font-size: 22px;
		border-radius: 8px;
	}
}

.title {
	width: calc(100% - 24px);
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	bottom: gutter(1);
	left: 50%;
	transform: translate(-50%, 0);
	gap: gutter(0.5);
	z-index: 1;
	letter-spacing: 0.04em;
	line-height: 1;
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	color: var(--cannes);

	&:deep(> span) {
		font-size: 14px;
		font-weight: 400;
		text-shadow: none;
		text-transform: none;
	}

	@include media-breakpoint-down(md) {
		font-size: 10px;

		&:deep(> span) {
			font-size: 10px;
		}
	}
}

.card-wrapper {
	&:hover .card > div {
		opacity: 1;
	}

	.badge-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: gutter(0.5);
		position: absolute;
		top: 8px;
		left: 8px;
		z-index: 2;
	}

	.top-block {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		top: 8px;
		left: 0;
		width: 100%;
		padding-right: 8px;
		z-index: 2;

		.fav-icon:deep(svg) {
			@include media-breakpoint-down(md) {
				width: 16px;
				height: 16px;
			}
		}
	}

	.card.game {
		background-color: var(--chattogram);

		&:before {
			content: "";
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: v-bind(imagePattern);
			position: absolute;
			z-index: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
	}
}

.game-content {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: gutter(2);
	z-index: 1;

	.center-block {
		position: absolute;
		display: flex;
		justify-content: center;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		right: 0;

		button.play {
			width: 50px;
			height: 50px;
			font-size: 26px;
		}
	}
}
</style>
